import React from "react";
import { Form } from "react-bootstrap";
import { OnChangeEventProps, InputDataProps } from "./InputEventInterface";
import Tooltip from "../../Tooltip";
import "./styles/Input.scss";

const InputBox: React.FC<InputDataProps> = ({
  label,
  type,
  name,
  id,
  hint,
  value,
  placeholder,
  required,
  onChange,
  onBlur,
  error,
  icon,
  top,
  pretext,
  className,
  autoComplete = "true",
  maxLength,
  getRawEvent = false,
  autoFocus = false,
  styles = {},
  withCounter = false,
  maxLimit = 0,
  disabled = false,
  ...restProps
}: InputDataProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value }: OnChangeEventProps = e.target;
    if (maxLimit && value.length > maxLimit || withCounter) {
      return;
    }
    if (!getRawEvent) onChange({ name, value });
    else onChange(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (onBlur) {
      const { name, value }: OnChangeEventProps = e.target;
      if (!getRawEvent) onBlur({ name, value });
      else onBlur(e);
    }
  };

  return (
    <Form.Group className={`${top?.length >0 ? "top-"+`${top}` + " ": " top-28 "}`+ className + " theme_wrapper"}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label}
          {required && <span className="required-input-field">*</span>}
          {hint && (
            <Tooltip
              label={<div className="bg-icon bg-help-circle bg-icon16 icon-grey icon-left12" />}
              variant="primary"
              description={<span>{hint}</span>}
              placement="top"
            />
          )}
        </Form.Label>
      )}
      <div className={`position-relative input-field-font ${pretext ? 'pretext-container' : ''}`}>
        {icon ? <span className="input-icon">{icon}</span> : ""}
        {pretext ? <span className="pretext">HTTPS://</span> : ""}
        <Form.Control
          className={`input-field form-input 
          ${disabled ? "disabled" : ""} ${icon ? "have-icon" : ""}

          ${value ? (value.length === 0 ? "" : "typed") : ""}
              ${error ? "error" : ""}`}
          name={name}
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onKeyPress={(e) => {
            if (type === "number") {
              e = e || window.event;
              var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
              var charStr = String.fromCharCode(charCode);

              if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
            }
          }}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          isInvalid={!!error}
          autoComplete={autoComplete}
          maxLength={maxLength}
          autoFocus={autoFocus}
          style={styles}
          top={top}
          disabled={disabled}
          {...restProps}
        />
        {withCounter && (
          <div className="counter">
            {value?.length}/{maxLimit}
          </div>
        )}

        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default InputBox;
